<template>
  <div class="my-template-manufacture-inquiry">
    <div class="container">
      <div class="tabs">
        <div
          class="tab unselect"
          v-for="(tab, index) in tabs"
          :key="`tab-${index}`"
          @click="clickTab(tab.value)"
          :class="{ active: selectedStatus === tab.value }"
        >
          {{ tab.label }}
        </div>
      </div>
      <div class="body4 sub count-wrapper">전체 {{ page.count }}개</div>
      <div class="list">
        <card-template-manu-facture
          v-for="(item, index) in list"
          :key="`list-${index}`"
          :card="item"
          @openProductionReqModal="openProductionReqModal"
        />
      </div>
    </div>
    <production-req-modal ref="productionReqModal" :product="selectedProduct" />
  </div>
</template>
<script>
import CardTemplateManuFacture from '@/components/component/CardTemplateManuFacture.vue';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
const io = require('socket.io-client');
import ProductionReqModal from '../modal/ProductionReqModal.vue';
export default {
  name: 'UserTemplateManufactureInquiry',
  components: {
    CardTemplateManuFacture,
    ProductionReqModal,
  },
  mixins: [PartnerAPIMixin],
  props: {},
  data() {
    return {
      tabs: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '상담중',
          value: 1,
        },
        {
          label: '상담완료',
          value: 2,
        },
        {
          label: '계약확정',
          value: 3,
        },
      ],
      selectedStatus: '',
      list: [],
      page: {
        cur_page: 1,
        total_page: 1,
      },
      selectedProduct: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.setSocketIO();
    this.getData();
  },
  mounted() {},
  methods: {
    openProductionReqModal(item) {
      this.selectedProduct = item;
      this.$nextTick(() => {
        this.$refs.productionReqModal.open();
      });
    },
    setSocketIO() {
      this.socket = io.connect('https://master-dev.launchpack.co.kr:3000');
      setTimeout(() => {
        this.socket.on('message', (msg) => {
          if (msg.type === 'template') {
            this.list.find((item) => item.id === msg.message.inquiry_id).message_count++;
          }
        });
      }, 500);
    },
    getData() {
      let params = {
        cur_page: this.page.cur_page,
      };
      if (this.selectedStatus) {
        params.status = this.selectedStatus;
      }
      if (this.page.cur_page > this.page.total_page) {
        return;
      }
      this.request.partner.get('launchpack/template_manufacture_inquiry', { params: params }).then((res) => {
        this.list = res.data.data;
        this.page.cur_page = res.data.cur_page;
        this.page.total_page = res.data.total_page;
        this.page.count = res.data.count;
      });
    },
    clickTab(value) {
      this.selectedStatus = value;
      this.page.cur_page = 1;
      this.getData();
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.my-template-manufacture-inquiry
  display flex
  justify-content center
  .container
    width 1152px
    .tabs
      display flex
      gap 8px
      .tab
        padding 7px 14px
        border-radius 50px
        border 1px solid $gray
        background-color white
        font-size 14px
        font-weight 500
        &.active
          background-color $main
          color white
    .list
      margin-top 24px
      display flex
      flex-direction column
      gap 12px
.count-wrapper
  margin 24px 0 16px
</style>
